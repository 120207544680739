import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import IntroArea from "@components/introarea/layout-2";
import IntroAreaReverse from "@components/introareareverse/layout-2";
import BoxSection from "@components/BoxSection/layout-two/layout-2";
import IntroAreaReverseThree from "@components/introareareverse/layout-3";
import IntroAreaThree from "@components/introarea/layout-3";
import ImageSection from "@components/ImageBox";
import CaseStudyFacts from "@containers/case-study/facts";

const CaseStudySpotify = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  return (
    <Layout location={location}>
      <Seo title="Case study - Spotify localization"
      description="Spotify partnered with Andovar for user interface localization, translating the app and marketing materials into a variety of other Asian languages and testing the final product." 
      />
      <Header
        data={{
          ...globalContent["header"],
          ...globalContent["menu"],
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["case-study-spotify-header"]} />
        <ImageSection data={content["case-study-spotify-image"]}/>
        <IntroArea data={content["case-study-spotify-client-body"]}/>
        <CaseStudyFacts data={content["spotify-fast-facts"]} />
        <IntroAreaReverse data={content["case-study-spotify-project-body"]} />
        <BoxSection layout={2} data={content["case-study-spotify-services"]}/>
        <IntroAreaReverseThree layout={2} data={content["case-study-spotify-language"]} />
        <IntroAreaThree data={content["case-study-spotify-volume"]}/>
        <IntroAreaReverseThree layout={2} data={content["case-study-spotify-application"]} />
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};

export const query = graphql`
  query caseStudySpotifyPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "case-studies" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
  }
`;

CaseStudySpotify.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default CaseStudySpotify;
